// Dependencies imports
import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { useDispatch, useSelector } from "react-redux";
import { API } from "aws-amplify";
import Skeleton from "@material-ui/lab/Skeleton";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/styles";
import swal from "sweetalert";
import UserBulkUploadTenant from "./UserBulkUploadTenant";
import moment from "moment";
import { ReactComponent as Close } from "../../assets/svg/close_black_24dp.svg";
// Local imports
import UserHeader from "../Header/UserHeader/UserHeader";
import DoughnutChart from "./DoughnutChart";
import Ios from "../../assets/images/apple-black-logo.png";
import Android from "../../assets/images/android.png";
import Web from "../../assets/images/global.png";
import UserStatsGrid from "./UserStatsGrid";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";
import AddUser from "./AddUser";
import ExamSubmit from "./ExamSubmit";
import SendNotification from "./SendNotification";
import UserBulkUpload from "../Users/UserBulkUpload";
import UserBulkRegistartion from "./UserBulkRegistration";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
// Styles imports
import Use from "./UserStats.module.scss";
// Materail Styles for Modal
const useStyles = makeStyles({
    root: {
        "& .super-app-theme--header": {
            backgroundColor: Constants.main_color_1,
            fontSize: "16px",
            color: Constants.app_color,
            fontFamily: "nunito",
        },
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "none",
        transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
});
// Start of User Stats component
const UserStats = () => {
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState([]);
    const [totalUser, setTotalUser] = useState("");
    const [totaActiveUsers, setTotaActiveUsers] = useState("");
    const [totalDesktopUsers, setTotalDesktopUsers] = useState("");
    const [totalIphoneUsers, setTotalIphoneUsers] = useState("");
    const [totalAndroidUsers, setTotalAndroidUsers] = useState("");
    const [genderChartData, setGenderChartData] = useState([]);
    const [openUser, setOpenUser] = useState(false);
    const [openExam, setOpenExam] = useState(false);
    const [openEmail, setOpenEmail] = useState(false);
    const [openBulk, setOpenBulk] = useState(false);
    const [openBulkUsers, setOpenBulkUsers] = useState(false);
    const [rowdata, setRowdata] = useState(null);
    const [checked, setChecked] = React.useState({ CDD: false, EAD: false });
    const [selectionModel, setSelectionModel] = useState([]);
    const [mailUserList, setMailUserList] = useState();
    const [activeTodayList, setActiveTodayList] = useState([]);
    const [iosList, setIosList] = useState([]);
    const [androidList, setAndroidList] = useState([]);
    const [webList, setWebList] = useState([]);
    const [platformUsersList, setPlatformUsers] = useState([]);
    const [platform, setPlatform] = useState([]);
    const [openActiveToday, setOpenActiveToday] = useState(false);
    const [openUsersByPlatForm, setOpenUsersByPlatForm] = useState(false);
    const [age, setAge] = useState(0);
    const [openScore, setOpenScore] = useState(false);
    const [openRegUsers, setOpenRegUsers] = useState(false);
    const [allTests, setAllTests] = useState([]);
    const classes = useStyles();
    const dispatch = useDispatch();
    let userDetails = useSelector(authData);

    const tableStyle = {
        borderCollapse: "collapse",
        width: "100%",
    };

    const tdStyle1 = {
        padding: "8px",
        textAlign: "left",
        border: "1px solid #ddd",
        fontSize: "14px",
    };

    const thStyle = {
        backgroundColor: "#f2f2f2",
        padding: "8px",
        textAlign: "left",
        border: "1px solid #ddd",
        fontSize: "16px",
    };

    const tdStyle = {
        padding: "8px",
        textAlign: "left",
        border: "1px solid #ddd",
        fontSize: "14px",
        cursor: "pointer",

    };
    useEffect(() => {
        getFaculty();
        breadcrumb();
        getAllTests();
        return () => { };
    }, []);

    // Api call to get faculty details
    async function getFaculty() {
        setLoading(true);
        const bodyParam = {
            body: {
                oid: config.aws_org_id,
                bpid: userDetails.curprgcou.bpid,
            },
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        try {
            const users = await API.post(
                config.aws_cloud_logic_custom_name,
                Constants.GET_PROGRAM_BATCH_REPORT,
                bodyParam
            );

            convertData(users);
        } catch (error) {
            console.error(error);
        }
    }
   
    function convertData(data) {
        let temp = [];

        for (let i = 0; i < data.length; i++) {
            let obj = {};
            obj.id = i;
            obj.name = data[i].UNAME;
            obj.email = data[i].EMAILID;
            obj.wbid = data[i].WBID;
            obj.eid = data[i].EID;
            obj.electives =
                data[i].ELECTIVES != undefined
                    ? data[i].ELECTIVES[userDetails.curprgcou.bpid]
                    : "";
            if (
                data[i].USN != undefined &&
                data[i].USN[userDetails.curprgcou.bpid] != undefined &&
                data[i].USN[userDetails.curprgcou.bpid].usn != undefined
            ) {
                obj.usn = data[i].USN[userDetails.curprgcou.bpid].usn;
                obj.examcode = data[i].USN[userDetails.curprgcou.bpid].examcode;
                obj.FirstDate = data[i].USN[userDetails.curprgcou.bpid].FirstDate;
                obj.LastDate = data[i].USN[userDetails.curprgcou.bpid].LastDate;
            } else {
                obj.usn = "";
                obj.examcode = "";
                obj.FirstDate = "";
                obj.LastDate = "";
            }
            if (data[i].EXAM != undefined) obj.exam = data[i].EXAM;
            obj.userrole = data[i].UR;

            if (data[i].LA === undefined) {
            } else {
               
                obj.LA = moment.utc(data[i].LA).fromNow();
            }
           
            if (obj.userrole === "User") {
                temp.push(obj);
            }
        }

        let correctPlatformUsers = 0;
        let tdev = 0,
            android = 0,
            iphone = 0,
            desktop = 0,
            male = 0,
            female = 0,
            unknown = 0,
            activeusers = 0;
        let instructorList = [],
            activeUsersList = [],
            webUserList = [],
            androidUserList = [],
            iosuserList = [],
            userList = [];
        for (let k = 0; k < data.length; k++) {
            if (data[k].UR === "Instructor") {
                instructorList.push(data[k]);
            } else if (data[k].UR === "User") {
                userList.push(data[k]);
                if (data[k].UA === 1 || data[k].UA === "1") {
                }

                const date1 = new Date(data[k].LA);
                const date2 = new Date();
                const differenceInMilliseconds = date2 - date1;
                const differenceInDays = Math.round(
                    differenceInMilliseconds / (1000 * 60 * 60 * 24)
                );

                if (differenceInDays == 0) {
                    activeusers++;
                    activeUsersList.push(data[k]);
                }

                setActiveTodayList(activeUsersList);

                if (data[k].DEV === "Desktop") {
                    desktop++;
                    webUserList.push(data[k]);
                } else if (data[k].DEV === "Android") {
                    android++;
                    androidUserList.push(data[k]);
                } else if (data[k].DEV === "iPhone") {
                    iphone++;
                    iosuserList.push(data[k]);
                } else {
                    correctPlatformUsers++;
                }
                if (data[k].GEN === "Male") male++;
                else if (data[k].GEN === "Female") female++;
                else unknown++;

                tdev++;
            }
        }

        setWebList(webUserList);
        setAndroidList(androidUserList);
        setIosList(iosuserList);

        let tusers = userList.length;

        let ios, andrd, dtop;
        let gender = [];
        gender.push(male, female, unknown);

        if (userList.length > 0) {
            ios = (+iphone / +tusers) * 100;
            if (ios === Math.floor(ios)) ios = ios;
            else ios = ios.toFixed(2);

            andrd = (+android / +tusers) * 100;

            if (andrd === Math.floor(andrd)) andrd = andrd;
            else andrd = andrd.toFixed(2);

            dtop = (+desktop / +tusers) * 100;
            if (dtop === Math.floor(ios)) dtop = dtop;
            else dtop = dtop.toFixed(2);
        } else {
            ios = 0;
            andrd = 0;
            dtop = 0;
        }
        setUserData(temp);
        setTotalUser(tusers);
        setTotaActiveUsers(activeusers);
        setTotalDesktopUsers(dtop);
        setTotalIphoneUsers(ios);
        setTotalAndroidUsers(andrd);
        setGenderChartData(gender);
        setLoading(false);
    }

    async function getAllTests() {

        setLoading(true);
        const bodyParam = {
            body: {
                oid: config.aws_org_id,
                bpid: userDetails.curprgcou.bpid,
            },
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        try {
            const response = await API.post(
                config.aws_cloud_logic_custom_name,
                Constants.GET_ALL_TESTS,
                bodyParam
            );
            setLoading(false);

            var obj = response;
            let testarray = [];
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    const test = obj[key];
                    var newObj = {};
                    newObj.testid = test['Test ID'];
                    newObj.testname = test['Test Name'];
                    newObj.startDate = test['Start Date'];
                    newObj.endDate = test['End Date'];
                    newObj.Domain = test['Domain'];
                    testarray.push(newObj);
                }
            }
            const filteredItems = testarray.filter(item => {
              if (typeof item.Domain === 'string' && typeof userDetails?.curprgcou?.bname === 'string') {
                return item.Domain.toLowerCase() === userDetails.curprgcou.bname.toLowerCase();
              }
              return false;
            });
            setAllTests(filteredItems);
        } catch (error) {
            console.error(error);
        }
    }

    function breadcrumb() {
        let sdata = { ...userDetails };
        let temp = [...sdata.breadcrumb];

        temp[2] = {
            name: "Users",
            path: "/UserStats",
        };

        sdata.breadcrumb = temp;

        dispatch(awsSignIn(sdata));
    }

    const handleEmailOpen = () => {
        recvDataMail(selectionModel);
        setOpenEmail(true);
    };

    const handleEmailClose = () => {
        setOpenEmail(false);
    };

    const handleUserOpen = () => {
        setOpenUser(true);
    };

    const handleUserClose = () => {
        setOpenUser(false);
    };

    const handleBulkOpenUsers = () => {
        setOpenBulkUsers(true);
    };

    const handleBulkCloseUsers = () => {
        setOpenBulkUsers(false);
    };

    const handleBulkRegOpen = () => {
        setOpenRegUsers(true);
    };

    const handleBulkRegClose = () => {
        setOpenRegUsers(false);
    };

    const handleExamOpen = () => {
        setOpenExam(true);
    };

    const handleExamClose = () => {
        setOpenExam(false);
    };
    function recvDataMail(indexArr) {
        let userList = [];

        for (let i = 0; i < indexArr.length; i++) {
            for (let k = 0; k < userData.length; k++) {
                if (userData[k].id === indexArr[i]) {
                    userList.push(userData);
                }
            }
        }

        setMailUserList(userList);
    }

    const handleFileUpload = (e) => {

        const file = e.target.files[0];

        const reader = new FileReader();
        reader.onload = (e) => {
            const xd = e.target.result;

            var array = CSVToArray(xd);

            var objArray = [];
            for (var i = 1; i < array.length; i++) {
                objArray[i - 1] = {};
                for (var k = 0; k < array[0].length && k < array[i].length; k++) {
                    var key = array[0][k];
                    objArray[i - 1][key] = array[i][k];
                }
            }

            bulkregister(objArray);
        };
        reader.readAsText(file);
    };

    async function bulkregister(afile) {
        if (afile != undefined || afile != null) {
            let arrays = [];
            for (var i = 0; i < afile.length; i++) {
                if (afile[i].Name.length > 0) {
                    afile[i].usn = afile[i]["Candidate Id"];
                    arrays.push(afile[i]);
                }
            }
            setLoading(true);
            const bodyParam = {
                body: {
                    oid: config.aws_org_id,
                    bpid: userDetails.curprgcou.bpid,
                    jsonfile: arrays,
                },
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            };
            if (checked.CDD) {
                bodyParam.body.cdd = true;
            }

            if (checked.EAD) {
                bodyParam.body.ead = true;
            }
            try {
               await API.post(
                    config.aws_cloud_logic_custom_name,
                    Constants.UserPreRegistration,
                    bodyParam
                );
                setLoading(false);

                swal({
                    title: "Success",
                    text: "Updated Successfully",
                    icon: "success",
                    dangerMode: false,
                }).then(willOk => {
                    if (willOk) {
                        getFaculty();
                    }
                });

            } catch (error) {
                swal({
                    title: "Oooops!",
                    text: "Registration Not Done",
                    icon: "warning",
                    dangerMode: true,
                });
            }
        }
    }

    const CSVToArray = function (strData, strDelimiter) {
        strDelimiter = strDelimiter || ",";

        var objPattern = new RegExp(
            "(\\" +
            strDelimiter +
            "|\\r?\\n|\\r|^)" +
            '(?:"([^"]*(?:""[^"]*)*)"|' +
            '([^"\\' +
            strDelimiter +
            "\\r\\n]*))",
            "gi"
        );

        var arrData = [[]];

        var arrMatches = null || "";

        while ((arrMatches = objPattern.exec(strData))) {
            var strMatchedDelimiter = arrMatches[1];

            if (strMatchedDelimiter.length && strMatchedDelimiter != strDelimiter) {
                arrData.push([]);
            }

            if (arrMatches[2]) {
                var strMatchedValue = arrMatches[2].replace(new RegExp('""', "g"), '"');
            } else {
                strMatchedValue = arrMatches[3];
            }

            arrData[arrData.length - 1].push(strMatchedValue);
        }

        return arrData;
    };
    const handleBulkOpen = () => {
        setOpenBulk(true);
    };

    const handleBulkClose = () => {
        setOpenBulk(false);
    };

    const handleScoreOpen = () => {
        setOpenScore(true);
    };

    const handleScoreClose = () => {
        setOpenScore(false);
    };

    const handleActiveOpen = () => {
        setOpenActiveToday(true);
    };

    const handleActiveClose = () => {
        setOpenActiveToday(false);
    };

    const handlePlatFormOpen = (platform) => {
        setOpenUsersByPlatForm(true);

        if (platform == "ios") {
            setPlatformUsers(iosList);
            setPlatform("IOS");
        }

        if (platform == "android") {
            setPlatformUsers(androidList);
            setPlatform("Android");
        }

        if (platform == "web") {
            setPlatformUsers(webList);
            setPlatform("Web");
        }
    };

    const handlePlatFormClose = () => {
        setOpenUsersByPlatForm(false);
    };

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    return (
        <div className={Use.containermain}>
            <UserHeader Bindex={2} />
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openUser}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openUser}>
                    <AddUser handleUserClose={handleUserClose} getFaculty={getFaculty} />
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openExam}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openExam}>
                    <ExamSubmit
                        handleExamClose={handleExamClose}
                        getFaculty={getFaculty}
                    />
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openEmail}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openEmail}>
                    <SendNotification
                        handleEmailClose={handleEmailClose}
                        mailUserList={mailUserList}
                        toTenants={true}
                    />
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openBulk}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openBulk}>
                    <UserBulkUploadTenant
                        handleClose={handleBulkClose}
                        type="batch"
                        getUsers={getFaculty}
                        rows={userData}
                        rowdata={rowdata}
                    />
                </Fade>
            </Modal>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openScore}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openScore}>
                    <UserBulkUploadTenant
                        handleClose={handleScoreClose}
                        type="score"
                        getUsers={getFaculty}
                        rows={userData}
                        rowdata={rowdata}
                    />
                </Fade>
            </Modal>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openBulkUsers}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openBulkUsers}>
                    <UserBulkUpload handleClose={handleBulkCloseUsers} type="batch" />
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openRegUsers}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openRegUsers}>
                    <UserBulkRegistartion
                        handleClose={handleBulkRegClose}
                        getUsers={getFaculty}
                        rows={userData}
                        rowdata={rowdata}
                        type={'add'}
                        tests={allTests}
                    />
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openActiveToday}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openActiveToday}>
                    <div
                        style={{
                            backgroundColor: "white",
                            width: "70rem",
                            transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                            transform: "translate(-50%, -50%)",
                            position: "absolute",
                            height: "max-content",
                            maxHeight: "80vh",
                            padding: "2rem",
                            top: "50%",
                            left: "50%",
                            textAlign: "center",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                height: "max-content",
                                marginBottom: "2rem",
                            }}
                        >
                            <h3>Users Active Today</h3>
                            <Close
                                className={Use.closebutton}
                                style={{ cursor: "pointer" }}
                                onClick={handleActiveClose}
                            />
                        </div>

                        <div
                            style={{ marginTop: "30px", overflow: "auto", height: "64vh" }}
                        >
                            {activeTodayList == undefined || activeTodayList.length == 0 ? (
                                <h3 style={{ textAlign: "left" }}>No Data</h3>
                            ) : (
                                <table style={tableStyle}>
                                    <thead>
                                        <tr>
                                            <th style={thStyle}>Sl No</th>
                                            <th style={thStyle}>Name</th>
                                            <th style={thStyle}>Email Id</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {activeTodayList.map((item, index) => (
                                            <tr key={index}>
                                                <td style={tdStyle1}>{index + 1}</td>
                                                <td style={tdStyle}>{item.UNAME}</td>
                                                <td style={tdStyle1}>{item.EMAILID}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                </Fade>
            </Modal>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openUsersByPlatForm}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openUsersByPlatForm}>
                    <div
                        style={{
                            backgroundColor: "white",
                            width: "70rem",
                            transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                            transform: "translate(-50%, -50%)",
                            position: "absolute",
                            height: "max-content",
                            maxHeight: "80vh",
                            padding: "2rem",
                            top: "50%",
                            left: "50%",
                            textAlign: "center",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                height: "max-content",
                                marginBottom: "2rem",
                            }}
                        >
                            <h3>Users by {platform} platform </h3>
                            <Close
                                className={Use.closebutton}
                                style={{ cursor: "pointer" }}
                                onClick={handlePlatFormClose}
                            />
                        </div>

                        <div
                            style={{ marginTop: "30px", overflow: "auto", height: "64vh" }}
                        >
                            {platformUsersList == undefined ||
                                platformUsersList.length == 0 ? (
                                <h3 style={{ textAlign: "left" }}>No Data</h3>
                            ) : (
                                <table style={tableStyle}>
                                    <thead>
                                        <tr>
                                            <th style={thStyle}>Sl No</th>
                                            <th style={thStyle}>Name</th>
                                            <th style={thStyle}>Email Id</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {platformUsersList.map((item, index) => (
                                            <tr key={index}>
                                                <td style={tdStyle1}>{index + 1}</td>
                                                <td
                                                    style={tdStyle}

                                                >
                                                    {item.UNAME}
                                                </td>

                                                <td style={tdStyle1}>{item.EMAILID}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                </Fade>
            </Modal>
            <div className={Use.topsection}>
                <div className={Use.users}>
                    <h3 className={Use.leftsection}>Total users</h3>
                    <Paper
                        className={Use.count}
                        style={{
                            height: "140px",
                            width: "140px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <h4 className={Use.number}>
                            {loading === false ? (
                                totalUser
                            ) : (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Skeleton variant="rect" width={50} height={28} />{" "}
                                </div>
                            )}
                        </h4>
                    </Paper>
                </div>
                <div className={Use.today}>
                    <h3 className={Use.leftsection}>Active today</h3>
                    <Paper
                        className={Use.count}
                        style={{
                            height: "140px",
                            width: "140px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <h4
                            className={Use.number}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                handleActiveOpen();
                            }}
                        >
                            {loading === false ? (
                                totaActiveUsers
                            ) : (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Skeleton variant="rect" width={50} height={28} />{" "}
                                </div>
                            )}
                        </h4>
                    </Paper>
                </div>
                <div className={Use.platform}>
                    <h3 className={Use.leftsection}>Users by platform</h3>
                    <Paper
                        style={{ width: "350px", height: "140px", padding: "5px 50px" }}
                    >
                        <div className={Use.row}>
                            <div className={Use.iconholder}>
                                <img src={Ios} alt="Ios" />
                            </div>
                            <div className={Use.device}>
                                <h4
                                    className={Use.type}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        handlePlatFormOpen("ios");
                                    }}
                                >
                                    IOS
                                </h4>
                            </div>
                            <div className={Use.percentholder}>
                                <h5 className={Use.percent}>
                                    {loading === false ? (
                                        `${totalIphoneUsers} %`
                                    ) : (
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Skeleton variant="rect" width={20} height={20} />{" "}
                                        </div>
                                    )}
                                </h5>
                            </div>
                        </div>
                        <div className={Use.row}>
                            <div className={Use.iconholder}>
                                <img src={Android} alt="android" />
                            </div>
                            <div className={Use.device}>
                                <h4
                                    className={Use.type}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        handlePlatFormOpen("android");
                                    }}
                                >
                                    Android
                                </h4>
                            </div>
                            <div className={Use.percentholder}>
                                <h5 className={Use.percent}>
                                    {loading === false ? (
                                        `${totalAndroidUsers} %`
                                    ) : (
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Skeleton variant="rect" width={20} height={20} />{" "}
                                        </div>
                                    )}
                                </h5>
                            </div>
                        </div>
                        <div className={Use.row}>
                            <div className={Use.iconholder}>
                                <img src={Web} alt="web" />
                            </div>
                            <div className={Use.device}>
                                <h4
                                    className={Use.type}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        handlePlatFormOpen("web");
                                    }}
                                >
                                    Web
                                </h4>
                            </div>

                            <div className={Use.percentholder}>
                                <h5 className={Use.percent}>
                                    {loading === false ? (
                                        `${totalDesktopUsers} %`
                                    ) : (
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Skeleton variant="rect" width={20} height={20} />{" "}
                                        </div>
                                    )}
                                </h5>
                            </div>
                        </div>
                    </Paper>
                </div>
                <div className={Use.gender}>
                    <h3 className={Use.leftsection}>Users by gender</h3>
                    <Paper
                        style={{ width: "350px", height: "140px", paddingTop: "20px" }}
                    >
                        <div className={Use.graph}>
                            {loading === false ? (
                                <DoughnutChart genderChartData={genderChartData} />
                            ) : (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Skeleton variant="rect" width={140} height={80} />{" "}
                                </div>
                            )}
                        </div>
                    </Paper>
                </div>
            </div>
            <div className={Use.bottomsection}>
                <div className={Use.topbar}>
                    <div className={Use.headercontainer}>
                        <h3 className={Use.header}>User activity</h3>
                    </div>

                    <div className={Use.rightsection}>
                        {selectionModel != undefined && selectionModel.length !== 0 ? (
                            <button
                                className={Use.notification}
                                onClick={() => {
                                    handleEmailOpen();
                                }}
                            >
                                Send email/notification
                            </button>
                        ) : null}
                        <div style={{ "margin-left": "auto", width: '135px' }}>
                            <p style={{ fontSize: '12px', fontWeight: 'bold' }}>Candidate ID :</p>
                            <input type="file" accept=".csv,.xlsx,.xls" onChange={(e) => handleFileUpload(e)} />

                        </div>
                        <button
                            className={Use.notification}
                            onClick={() => {
                                handleUserOpen();
                            }}
                        >
                            Add users
                        </button>
                        <p style={{ fontSize: '15px' }}>Upload :</p>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={age}
                            label="Select Option"
                            onChange={handleChange}
                            style={{ border: "none", width: "150px", boxShadow: "0px 0px 5px 0px lightgrey", background: "white", height: "30px", fontSize: "15px", fontWeight: "200px" }}
                        >
                            <MenuItem value={0} >Select Option</MenuItem>
                            <MenuItem value={1} onClick={() => {
                                handleBulkOpenUsers();
                            }}>Bulk Upload</MenuItem>
                            <MenuItem value={2} onClick={() => {
                                handleExamOpen();
                            }}>Final Exam</MenuItem>
                            <MenuItem value={3} onClick={handleBulkOpen}>Assign Electives</MenuItem>
                            <MenuItem value={5} onClick={handleScoreOpen}>Final Score</MenuItem>
                            <MenuItem value={3} onClick={handleBulkRegOpen}>Bulk Registration</MenuItem>
                        </Select>

                    </div>
                </div>
            </div>
            <div className={Use.datagrid}>
                <div style={{ height: 400, width: "100%" }} className={classes.root}>
                    <Paper>
                        {loading === false ? (
                            <UserStatsGrid
                                userData={userData}
                                selectionModel={selectionModel}
                                setSelectionModel={setSelectionModel}
                                allTests={allTests}
                                handleClose={handleBulkRegClose}
                            />
                        ) : (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "flex-start",
                                    paddingTop: "40px",
                                    height: "400px",
                                }}
                            >
                                <Skeleton variant="rect" width="90%" height={300} />{" "}
                            </div>
                        )}
                    </Paper>
                </div>
            </div>
        </div>
    );
};

export default UserStats;
