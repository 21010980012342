// Dependencies imports
import React, { useState, useEffect, useRef } from "react";
import { API } from "aws-amplify";
import ClockGray from "../../../assets/svgjs/ClockGray";
import Cviewstyle from "./courseView.module.scss";
import DialogBox from "./Dialogbox";
import "./courseView.scss";
import UserHeader from "../../Header/UserHeader/UserHeader";
import { Constants } from "../../../config/constants";
import config from "../../../config/aws-exports";
import CloseIcon from "@material-ui/icons/Close";
import { Tab } from "semantic-ui-react";

import { useSelector, useDispatch } from "react-redux";
import { setCookie } from "react-use-cookie";
import CoursePlayer from "./CoursePlayer";
import { awsSignIn, authData, } from "../../../redux/auth/authSlice";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import addpluse from "../../../assets/images/add.png";
import Scrom from "../../../assets/images/scorm.png";


// Local imports
import References from "./References";
import RecordSession from "./RecordSession";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Podcast from "./Podcast/Podcast"

// Style imports
import Caviewstyle from "../../../components/Programs/CourseCreation/courseCreation.module.scss";
import ObjectPopUp from "../../../components/Programs/CourseCreation/AddObjects";
import AssignmentAndAssessment from "../../../components/AssignmentAndAssessment/AssignmentAndAssessment";
import {
  Button,
  Input,
  Fade,
  Backdrop,
  Modal,
  AccordionSummary,

  Accordion,
  makeStyles,
  TextareaAutosize,
  Typography,

  CircularProgress,
} from "@material-ui/core";

import useWindowDimensions from "../../../modules/Window/Window";
import QuizIc from "../../../assets/svgjs/Quiz";
import PdfIcon from "../../../assets/svgjs/Pdf";


import Video from "../../../assets/svgjs/Video";
import Review from "../../../assets/svgjs/Review";
import Html from "../../../assets/svgjs/Html";
import Audio from "../../../assets/svg/Audio.svg";

import Skeleton from "@material-ui/lab/Skeleton";

import swal from "sweetalert";
import axios from "axios";

import RegexTextField from "../CourseCreation/RegexTextField";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: "20px",
    height: "fit-content",
    overflowY: "auto",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    borderRadius: "10px",
  },

  tooltipFull: {
    fontSize: 30,
  },
  bottomIcons: {
    color: "#000",
    "&:hover": {
      color: "#777",
    },
  },
  volumeButton: {
    color: "#000",
    fontSize: 40,
    transform: "scale(0.9)",
    "&:hover": {
      color: "#777",
      transform: "scale(1.03)",
      transitionTimingFunction: "cubic-bezier(0.1, 0.1, 0.25, 1)",
    },
  },
  volumeButtonExit: {
    color: "lightgray",
    fontSize: 40,
    transform: "scale(0.9)",
    "&:hover": {
      color: "#777",
      transform: "scale(1.03)",
      transitionTimingFunction: "cubic-bezier(0.1, 0.1, 0.25, 1)",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function CourseView({ handleToggleSidebar }) {
  const classes = useStyles();
  const onlyAlphanumericRegex = /[^0-9]/gi;

  const [courseDetails, setCourseDetails] = useState({
    tdescription: "Course Description",
  });
  const [objectData, setObjectData] = useState({});
  const [oIndex, setOIndex] = useState(0);
  const [curObject, setcurObject] = useState({});
  const curObRef = useRef({});
  const [assignment, setAssignment] = useState(false);
  const [assignmentLoad, setAssignmentLoad] = useState(true);
  const [cNugget, setcNugget] = useState({});
  const [errors, setErrors] = useState({ uval: false, nval: false });
  const [nuggets, setNuggets] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showPage, setShowPage] = useState(true);
  const [summative, setSummative] = useState(false);
  const [sumScore, setSumScore] = useState(false);
  const [objOpen, setObjOpen] = useState(false);

  const { width } = useWindowDimensions();

  const [qisLoading, setQIsLoading] = useState(false);
  const [sMiniScore, setSMiniScore] = useState(false);
  const [miniScore, setMiniScore] = useState(0);

  const [editObj, setEditObj] = useState({
    editbtn: true,
    meditmode: false,
    oeditmode: false,
    nuggetchanges: false,
    topicimgchanges: false,
  });
  const [selectedObj, setSelectedObj] = useState({});
  const redux = useRef({});
  const [folder, setFolder] = useState();
  const [nopen, setNopen] = useState();

  const [istLoading, setIstLoading] = useState(false);
  const [mainCourse, setMainCourse] = useState({});
  const [editquizincourse, setEditQuizInCourse] = useState(false);

  let userDetails = useSelector(authData);
  const refselectedCourseFile = useRef();
  const scrollRef = useRef();
  const accRef = useRef();
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(0);
  const [miniopen, setMiniopen] = useState(false);
  const [enableItem, setEnableItem] = useState({
    nenabled: "",
    nid: "",
    idx: "",
    ntitle: "",
  });
  let lcourseDetails = userDetails.curprg;

  const [sendMailIntern, setSendMailIntern] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    scrollRef.current.scrollTo(
      0,
      document.getElementById("acc" + panel).offsetTop - 100
    );
  };
  useEffect(() => {
    getCourseDetails();
    breadcrumb();
    reduxRestore();
  }, []);
  useEffect(() => { }, [nuggets]);

  async function getCourseDetails() {
    const bodyParam = {
      body: {
        btopicid: lcourseDetails.btid,
        oid: config.aws_org_id,
        topicid: lcourseDetails.tid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const courseDetails = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_COURSE_DETAILS,
        bodyParam
      );

      courseDetails.topicimg = `https://${config.aws_content_delivery_cloudfront_domain
        }/${config.aws_org_id.toLowerCase()}-resources/images/topic-images/${courseDetails.tid
        }.png`;
      setNuggets(courseDetails.nuggets);
      setFolder(courseDetails.tid);
      setCourseDetails(courseDetails);
      setMainCourse(courseDetails);
      setShowPage(courseDetails.tenabled);

      saveLocale(courseDetails);
      setIsLoading(false);
      let sdata = { ...redux.current };
      dispatch(awsSignIn(sdata));
    } catch (error) {

      console.error(error);
    }
  }
  function reduxRestore() {
    let sdata = { ...userDetails };
    redux.current = sdata;
  }

  async function validationcourse() {
    let
      tobj = 0,
      tvid = 0,
      tdoc = 0,
      tsq = 0,
      tfq = 0;
    if (courseDetails.ttitle == "" || courseDetails.ttitle.length === 0) {
      swal("Oops!", "Course title cannot be empty", "error");
      return;
    }
    if (
      courseDetails.tdescription == "" ||
      courseDetails.tdescription.length === 0
    ) {
      swal(
        "Oops!",
        "Course description cannot be empty in Overview section",
        "error"
      );
      return;
    }
    if (courseDetails.tduration == "" || courseDetails.tduration.length === 0) {
      swal("Oops!", "Course duration cannot be empty", "error");
      return;
    }
    if (courseDetails.tduration < 1) {
      swal("Oops!", "Course duration must be greater than 0", "error");
      return;
    }
    if (nuggets.length === 0) {
      swal("Oops!", "You need to add atleast 1 Module.", "error");
      return;
    }
    for (let k = 0; k < nuggets.length; k++) {
      tobj = tobj + nuggets[k].objects.length;
      if (nuggets[k].objects.length > 0) {
        for (let l = 0; l < nuggets[k].objects.length; l++) {
          if (
            nuggets[k].objects[l].otype == "video" ||
            nuggets[k].objects[l].otype == "audio"
          ) {
            tvid = tvid + 1;
          } else if (
            nuggets[k].objects[l].otype == "quiz" &&
            nuggets[k].objects[l].qtype == 1
          ) {
            tsq = tsq + 1;
          } else if (
            nuggets[k].objects[l].otype == "quiz" &&
            nuggets[k].objects[l].qtype != 1
          ) {
            tfq = tfq + 1;
          } else {
            tdoc = tdoc + 1;
          }
        }
      } else {
        swal(
          "Oops!",
          "You need to add atleast 1 Content in each Module",
          "error"
        );
        return;
      }
    }
    handleEditSave(tobj, tvid, tdoc, tsq, tfq);
  }
  const handleEditSave = async (tobj, tvid, tdoc, tsq, tfq) => {
    setIstLoading(true);
    let topicjson = { ...courseDetails };
    topicjson.nuggets = nuggets;
    topicjson.tobj = tobj;
    topicjson.tvid = tvid;
    topicjson.tdoc = tdoc;
    topicjson.tsq = tsq;
    topicjson.tfq = tfq;
    for (let q = 0; q < topicjson.nuggets.length; q++) {
      for (var a = 0; a < topicjson.nuggets[q].objects.length; a++) {
        delete topicjson.nuggets[q].objects[a].curl;
      }
    }
    delete topicjson.Policy;
    delete topicjson.Signature;
    delete topicjson.KeyPairId;
    delete topicjson.topicimg;
    const bodyParam = {
      body: {
        topicjsonchanges: false,
        nuggetchanges: true,
        topicjson,
        topicimgchanges: editObj.topicimgchanges,
        oid: config.aws_org_id,
        eid: userDetails.eid,
        tid: topicjson.tid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    if (userDetails.curprg !== undefined) {
      if (userDetails.curprg.btid !== undefined) {
        bodyParam.body.pid = userDetails.curprg.pid;
        bodyParam.body.btid = userDetails.curprg.btid;
      } else {
        bodyParam.body.pid = userDetails.curprg.pid;

      }
    }
    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        "/edittopic",
        bodyParam
      );

      window.location.reload();
    } catch (error) {
      console.log("getCategoryError", error);
    }
  };

  const handleClickOpen = () => {
    for (let i = 0; i < nuggets.length; i++) {
      if (nuggets[i].nid === enableItem.nid) {
        let len = nuggets[i].objects.length - 1;
        if (
          nuggets[i].objects[len].qtype == 1 ||
          nuggets[i].objects[len].qtype == 2
        ) {
          setMiniopen(true);

        } else {
          enableItem.email = true;
          setEnableItem(enableItem);
          enableDisablePost(
            enableItem.nenabled,
            enableItem.nid,
            enableItem.idx,
            enableItem.ntitle
          );
        }
      }
    }


  };


  const enableDisablePost = async (status, nid, idx, title) => {
    setIstLoading(true);

    const bodyParam = {
      body: {
        topicid: courseDetails.tid,
        oid: config.aws_org_id,
        btid: courseDetails.btid,
        nid,
        disable: !status,
        cname: courseDetails.ttitle,
        pname: userDetails.curprgcou.ptitle,
        title: title,
        bpid: userDetails.curprgcou.bpid,
        email: enableItem.email,
        emailIntern: enableItem.sendmailIntern,
        bname: userDetails.curprgcou.bname,
        disablebyemail: userDetails.email,
        disablebyName: userDetails.name,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    if (
      userDetails.curprg !== undefined &&
      userDetails.curprg.btid !== undefined
    ) {
      bodyParam.body.btid = userDetails.curprg.btid;
    }

    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        "/enableNuggets",
        bodyParam
      );


      nuggets[idx].nenabled = !status;
      setIstLoading(false);

    } catch (error) {
      console.log("enableNuggetserre", error);
    }

    enableItem.sendmailIntern = false;
    enableItem.email = false;
    setEnableItem(enableItem);
  };

  const renderSwitch = (param) => {
    if (param === "video") {
      return <Video className="video-size" cls1="cls-1--v" cls2="cls-2--v" />;
    } else if (param === "audio") {
      return <img src={Audio} style={{ height: "32px" }} />;
    } else if (param === "quiz") {
      return <QuizIc className="quizAct" />;
    } else if (param === "html") {
      return (
        <Html
          className="video-size"
          cls1="cls-1--H"
          cls2="cls-2--H"
          cls3="cls-3--H"
        />
      );
    } else if (param === "Interactivity" || param === "interactivity") {
      return <Review className="video-size" cls1="cls-1--R" cls2="cls-2--R" />;
    } else if (param === "scorm") {
      return <img src={Scrom} style={{ height: "32px" }} />;
    } else if (param === "pdf") {
      return <PdfIcon className="pdfAct" />;
    } else {
      return param;
    }
  };

  const saveLocale = (data) => {

    const expires = new Date().getTime() + 60 * 60 * 1000;
    setCookie("CloudFront-Expires", expires);
    setCookie("CloudFront-Policy", data.Policy);
    setCookie("CloudFront-Signature", data.Signature);
    setCookie("CloudFront-Key-Pair-Id", data.KeyPairId);
  };

  function renderObjectsext(objType) {
    switch (objType) {
      case "video":
        return "video/*";
      case "audio":
        return "audio/*";
      case "pdf":
        return ".pdf";
      case "html":
        return ".html";
      case "interactivity":
        return ".html, application/zip, application/x-zip-compressed, .zip";
      case "quiz":
        return ".json";
      default:
        return null;
    }
  }

  function CourseStructure() {
    return (
      <div className={classes.root}>
        {istLoading && (
          <Backdrop className={classes.backdrop} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Typography component="div" variant="h1">
          {isLoading ? <Skeleton /> : null}
        </Typography>
        <Typography component="div" variant="h1">
          {isLoading ? <Skeleton /> : null}
        </Typography>
        <Typography component="div" variant="h1">
          {isLoading ? <Skeleton /> : null}
        </Typography>
        <Typography component="div" variant="h1">
          {isLoading ? <Skeleton /> : null}
        </Typography>


        <DialogBox
          nuggets={nuggets}
          setNuggets={setNuggets}
          nopen={nopen}
          setNopen={setNopen}
          setIsLoading={setIsLoading}
          courseDetails={courseDetails}
          errors={errors}
          setErrors={setErrors}
        />
        {isLoading ? (
          <></>
        ) : (
          <>
            {nuggets.map(
              ({ nid, unit, ntitle, nduration, objects, nenabled }, idx) => (
                <Accordion
                  ref={accRef}
                  id={"acc" + idx}
                  key={idx}
                  expanded={expanded === `${idx}`}
                  onChange={handleChange(`${idx}`)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    {!editObj.meditmode && (
                      <>
                        <h2 className="size-m-b">{ntitle}</h2>
                      </>
                    )}
                    {editObj.meditmode && (
                      <>
                        <Input
                          defaultValue={ntitle}
                          className={Caviewstyle.inputsize}
                          onClick={(event) => event.stopPropagation()}
                          onFocus={(event) => event.stopPropagation()}
                          inputProps={{ "aria-label": "description" }}
                          onChange={(e) => {
                            courseDetails.nuggets[idx].ntitle = e.target.value;
                            setCourseDetails(courseDetails);
                          }}
                        />
                        <button
                          onClick={() => {
                            nuggets.splice(idx, 1);
                            setNuggets([...nuggets]);
                          }}
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            padding: "0px 20px",
                            background: "#e35f14",
                            border: "none",
                            borderRadius: "5px",
                            width: "194px",
                            color: "#fff",
                          }}
                        >
                          Remove Module
                        </button>{" "}
                      </>
                    )}
                    <p
                      style={{
                        fontSize: "14px",
                        position: "absolute",
                        right: "60px",
                      }}
                    >
                      {!editObj.meditmode && (
                        <>
                          {nenabled ? (
                            <>
                              <button
                                style={styles.enableDisable}
                                onClick={(event) => {
                                  setSendMailIntern(true);
                                  enableItem.nenabled = nenabled;
                                  enableItem.nid = nid;
                                  enableItem.idx = idx;
                                  enableItem.ntitle = unit;
                                  enableItem.sendmailIntern = true;
                                  enableItem.email = false;
                                  setEnableItem(enableItem);

                                  enableDisablePost(nenabled, nid, idx, unit);
                                  event.stopPropagation();
                                }}
                              >
                                Disable
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                style={styles.enableDisable}


                                onClick={() => {

                                  enableItem.nenabled = nenabled;
                                  enableItem.nid = nid;
                                  enableItem.idx = idx;
                                  enableItem.ntitle = unit;

                                  setEnableItem(enableItem);

                                  handleClickOpen();
                                }}
                              >
                                Enable
                              </button>
                            </>
                          )}

                        </>
                      )}
                    </p>
                  </AccordionSummary>

                  <div>
                    <div className="display-flex padding2"></div>
                    {objects.map((object, oidx) => (
                      <div
                        className="flex size"
                        style={{
                          width: "calc(100% - 4.5%)",
                        }}
                      >
                        {renderSwitch(object.otype)}
                        <p style={{ cursor: "pointer" }}>
                          <a
                            style={{ paddingLeft: "10px" }}
                            href
                            onClick={() => {
                              let obj = {};
                              obj.otherdata = {
                                title: object.otitle,
                                desc: object.odescription,
                                dur: object.oduration,
                                files: "",
                              };
                              if (
                                object.oduration !== undefined &&
                                object.oduration != ""
                              ) {
                                obj.otherdata.min = Math.floor(
                                  (object.oduration % 3600) / 60
                                );
                                obj.otherdata.sec = Math.floor(
                                  (object.oduration % 3600) % 60
                                );
                              }
                              obj.sobject = object.otype;
                              obj.otype = object.otype;
                              obj.oid = object.oid;
                              obj.seltype = true;
                              obj.error1 = false;
                              obj.afile = renderObjectsext(object.otype);
                              obj.oindex = oidx;
                              obj.nindex = idx;
                              obj.unit = unit;
                              obj.ourl = object.ourl;
                              if (object.otype === "quiz") {
                                obj.qtype = object.qtype;
                                if (object.qtype == 2 || object.qtype == "2") {
                                  obj.cutoff = object.cutoff;
                                }
                              }
                              setSelectedObj(obj);
                              setOIndex(oidx);
                              setcurObject(object);

                              setOpen(true);
                            }}
                          >
                            {object.otitle}
                          </a>
                        </p>
                        <span>
                          {editObj.meditmode && (
                            <button
                              onClick={() => {
                                nuggets[idx].objects.splice(oidx, 1);
                                setNuggets([...nuggets]);
                              }}
                              style={{
                                border: "none",
                                background: "transparent",
                              }}
                            >
                              <CloseIcon
                                fontSize="10px"
                                style={{ color: "red" }}
                              />
                            </button>
                          )}
                        </span>
                      </div>
                    ))}
                    {editObj.meditmode && (
                      <div className={Caviewstyle.addnugget}>
                        <div>
                          <p
                            style={{
                              fontSizesize: "14px",
                              margin: "5px 0",
                              textAlign: "center",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setcNugget({
                                nid,
                                ntitle,
                                objects,
                                idx,
                                cedit: true,
                              });
                              setObjOpen(true);
                            }}
                          >
                            <img
                              style={{
                                height: "25px",
                                border: "none",
                                cursor: "pointer",
                                margin: "0 15px",
                              }}
                              src={addpluse}
                            />
                            Add objects
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </Accordion>
              )
            )}
            {editObj.meditmode && (
              <div className={Caviewstyle.addnugget}>
                <div>
                  <p
                    style={{
                      fontSizesize: "14px",
                      margin: "5px 0",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setNopen(true);
                    }}
                  >
                    <img
                      style={{
                        height: "25px",
                        border: "none",
                        cursor: "pointer",
                        margin: "0 15px",
                      }}
                      src={addpluse}
                    />
                    Add Nugget
                  </p>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    );
  }

  const handleClose = () => {
    setOpen(false);
    editObj.oeditmode = false;
    setEditObj({ ...editObj });
  };

  const panes = [
    {
      menuItem: "Course structure",
      render: () => (
        <Tab.Pane>
          <CourseStructure />
        </Tab.Pane>
      ),
    },

    {
      menuItem: "Assignment",
      render: () => (
        <Tab.Pane>
          <AssignmentAndAssessment
            courseId={lcourseDetails.btid}
            subject={courseDetails.ttitle}
            type="Assignment"
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Assessment",
      render: () => (
        <Tab.Pane>
          <AssignmentAndAssessment
            courseId={lcourseDetails.btid}
            subject={courseDetails.ttitle}
            type="Assessment"
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Recorded session",
      render: () => (
        <Tab.Pane>
          <RecordSession />
        </Tab.Pane>
      ),
    },

    {
      menuItem: "Podcast",
      render: () => (
        <Tab.Pane>
          <Podcast
            pid={lcourseDetails.pid}
            bpid={lcourseDetails.bpid}
            courseid={lcourseDetails.tid}
            bcourseid={lcourseDetails.btid}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "References",
      render: () => (
        <Tab.Pane>
          <References />
        </Tab.Pane>
      ),
    },
  ];
  const handleTabChange = (aIndex) => {
    if (editObj.meditmode && aIndex != 0) {
      swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willsave) => {
        if (willsave) {
          setIsLoading(true);
          editObj.meditmode = false;
          editObj.editbtn = false;
          setCourseDetails({ ...mainCourse });
          setEditObj({ ...editObj });
          setActiveIndex(aIndex);
          setIsLoading(false);
          window.location.reload();
        } else {
          setActiveIndex(0);
        }
      });
    } else if (!editObj.meditmode) {
      if (aIndex == 0) {
        setEditObj({ ...editObj, editbtn: true });
        setNuggets(mainCourse.nuggets);
        setCourseDetails({ ...mainCourse });
      } else {
        setOpen(false);
        editObj.oeditmode = false;
        editObj.meditmode = false;
        editObj.editbtn = false;
        setEditObj({ ...editObj });
      }

      setActiveIndex(aIndex);
    }
  };
  const changeHandler = (event) => {
    if (event.target.files == null) {
      return;
    }
    if (event.target.files[0].size > 5242880) {
      swal(
        "Oops!",
        "Image size is too large. File size cannot be greater than 5mb.",
        "error"
      );
      return;
    }
    if (event.target.files[0] !== undefined) {
      refselectedCourseFile.current = event.target.files[0];
      var body = {
        filetype: event.target.files[0].type,
        filename: "topic.png",
        folder: courseDetails.tid,
      };

      uploadfile(body);
    }
  };
  async function uploadfile(body) {
    setIstLoading(true);
    const bodyParam = {
      body,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const preSignedData = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getpreSignedURL",
        bodyParam
      );

      axios
        .put(preSignedData, refselectedCourseFile.current, {
          headers: {
            "Content-Type": refselectedCourseFile.current.type,
            "Cache-Control": "no-cache no-store must-revalidate",
          },
        })
        .then((response) => {

          editObj.topicimgchanges = true;
          setEditObj({ ...editObj });
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
      setIstLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  function breadcrumb() {
    let sdata = { ...userDetails };
    let temp = [...sdata.breadcrumb];

    temp[3] = {
      name: "Course View",
      path: "/applicationdetails",
    };

    sdata.breadcrumb = temp;

    dispatch(awsSignIn(sdata));
  }
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        padding: "25px",
        overflowY: "auto",
      }}
      ref={scrollRef}
    >
      {/* <pre> {JSON.stringify(user, null, 2)}</pre> */}

      <div
        className="btn-toggle"
        onClick={() => handleToggleSidebar(true)}
      ></div>
      <UserHeader Bindex={3} />
      <ObjectPopUp
        objOpen={objOpen}
        setObjOpen={setObjOpen}
        renderSwitch={renderSwitch}
        folder={folder}
        cNugget={cNugget}
        setNuggets={setNuggets}
        nuggets={nuggets}
        editquizincourse={editquizincourse}
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div
            style={{
              width: width - 760,
              backgroundColor: "white",
              borderRadius: "10px",
            }}
          >
            {/* <div style={{width:"100%",height:"80px"}}>
            <span style={{float:"right",cursor:"pointer",color:"black"}} onClick={() => {handleClose()}}>x</span>
          </div> */}

            <CoursePlayer
              handleClose={handleClose}
              courseDetails={courseDetails}
              setCourseDetails={setCourseDetails}
              objectData={objectData}
              setObjectData={setObjectData}
              oIndex={oIndex}
              setOIndex={setOIndex}
              curObject={curObject}
              setcurObject={setcurObject}
              curObRef={curObRef}
              assignment={assignment}
              setAssignment={setAssignment}
              summative={summative}
              setSummative={setSummative}
              sumScore={sumScore}
              setSumScore={setSumScore}
              miniScore={miniScore}
              setMiniScore={setMiniScore}
              sMiniScore={sMiniScore}
              setSMiniScore={setSMiniScore}
              qisLoading={qisLoading}
              setQIsLoading={setQIsLoading}
              editObj={editObj}
              setEditObj={setEditObj}
              assignmentLoad={assignmentLoad}
              setAssignmentLoad={setAssignmentLoad}
              renderSwitch={renderSwitch}
              setSelectedObj={setSelectedObj}
              selectedObj={selectedObj}
              setNuggets={setNuggets}
              nuggets={nuggets}
              folder={courseDetails.tid}
              setOpen={setOpen}
              open={open}
              editquizincourse={editquizincourse}
            />
          </div>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalConfirm}
        open={miniopen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={miniopen}>
          {/* <div
            style={{
              backgroundColor: "white",
              width: "50rem",
              transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              transform: "translate(-50%, -50%)",
              position: "absolute",
              height: "20rem",
              padding: "2rem",
              top: "50%",
              left: "50%",
              textAlign: "center",
            }}
          >
            <h3>Are you sure you want to {enableItem.nenabled ? "Disable" : "Enable"}?</h3>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "40px",
                gap: "5rem",
              }}
            >
              <button
                style={{ padding: "0.5rem 1rem", fontSize: "1.5rem" }}
                onClick={(event) => {
                   enableDisablePost(enableItem.nenabled, enableItem.nid, enableItem.idx,enableItem.ntitle);
                  event.stopPropagation();
                  setMiniopen(false)
                }}
              >
                Confirm
              </button>

              <button style={{ padding: "0.5rem 1rem", fontSize: "1.5rem" }} onClick={()=>{handleClickclose()}}>
                Cancel
              </button>
            </div>
          </div> */}

          <div
            style={{
              backgroundColor: "white",
              width: "50rem",
              transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              transform: "translate(-50%, -50%)",
              position: "absolute",
              height: "20rem",
              padding: "2rem",
              top: "50%",
              left: "50%",
              textAlign: "center",
            }}
          >
            <h3>
              After enableling the Unit do you want to notify users through
              E-mail
            </h3>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "40px",
                gap: "5rem",
              }}
            >
              <button
                className={Cviewstyle.disablebutton}
                onClick={(event) => {
                  setMiniopen(false);
                  enableItem.email = false;
                  setEnableItem(enableItem);
                  enableDisablePost(
                    enableItem.nenabled,
                    enableItem.nid,
                    enableItem.idx,
                    enableItem.ntitle
                  );
                  event.stopPropagation();
                }}
              >
                Don't Send
              </button>

              <button
                className={Cviewstyle.enablebutton}
                onClick={(event) => {
                  setMiniopen(false);
                  enableItem.email = true;
                  setEnableItem(enableItem);
                  enableDisablePost(
                    enableItem.nenabled,
                    enableItem.nid,
                    enableItem.idx,
                    enableItem.ntitle
                  );
                  event.stopPropagation();
                }}
              >
                Send
              </button>
            </div>
          </div>
        </Fade>
      </Modal>

      <div className={Cviewstyle.management}></div>
      <div className={Cviewstyle.management_info}>
        <div className={Cviewstyle.h1} style={{ display: "flex" }}>
          <h1 style={{ color: "black", fontSize: "20px", width: "69%" }}>
            {!isLoading && <strong>{courseDetails.ttitle}</strong>}
            <Typography component="div" variant="h1">
              {isLoading ? <Skeleton /> : null}
            </Typography>
          </h1>
          {!isLoading && editObj.editbtn && (
            <button
              style={styles.editButton}
              onClick={() => {
                editObj.meditmode = true;
                editObj.editbtn = false;
                setEditObj({ ...editObj });
                setEditQuizInCourse(true);
              }}
            >
              Edit course
            </button>
          )}
          {editObj.meditmode && (
            <>
              <Button
                variant="contained"
                onClick={() => {
                  swal({
                    title: "Are you sure?",
                    text: "Save the changes",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                  }).then((willsave) => {
                    if (willsave) {

                      validationcourse();
                    }
                  });
                }}
                style={{
                  backgroundColor: "rgb(227, 95, 20)",
                  color: "#fff",
                  width: "100px",
                  height: "36px",
                  marginRight: "10px",
                  alignSelf: "center",
                }}
              >
                Save
              </Button>
              <Button
                variant="outlined"
                style={{ width: "100px", height: "36px", alignSelf: "center" }}
                onClick={() => {
                  swal({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                  }).then((willsave) => {
                    if (willsave) {
                      setIsLoading(true);
                      setCourseDetails({ ...mainCourse });
                      editObj.meditmode = false;
                      editObj.editbtn = true;
                      setEditObj({ ...editObj });
                      setIsLoading(false);
                      window.location.reload();
                    }
                  });
                }}
              >
                Discard
              </Button>
            </>
          )}
        </div>

        <div className={Cviewstyle.flex_box}>
          <div className={Cviewstyle.flex_box__container}>
            <div className={Cviewstyle.time_line}>
              <div>
                {!editObj.meditmode && (
                  <>
                    <span>
                      <ClockGray
                        className="clock-size"
                        cls1="cls1"
                        cls2="cls2"
                      />
                    </span>
                    <span style={{ fontSize: "15px" }}>
                      {courseDetails.tduration} weeks
                    </span>{" "}
                  </>
                )}
                {editObj.meditmode && (
                  <>
                    <RegexTextField
                      regex={onlyAlphanumericRegex}
                      label="Weeks"
                      defaultValue={courseDetails.tduration}
                      onChange={(e) => {
                        courseDetails.tduration = e.target.value;
                        setCourseDetails((prevcourseDetails) => ({
                          ...prevcourseDetails,
                          ...courseDetails,
                        }));
                      }}
                    />
                  </>
                )}
              </div>
            </div>
            <div>
              {editObj.meditmode && (
                <>
                  <TextareaAutosize
                    maxRows={15}
                    minRows={10}
                    aria-label="maximum height"
                    placeholder=""
                    defaultValue={courseDetails.tdescription}
                    style={{ width: "100%", fontSize: "20px" }}
                    onChange={(e) => {
                      courseDetails.tdescription = e.target.value;
                      setCourseDetails(courseDetails);
                    }}
                  />
                </>
              )}
              {!editObj.meditmode && (
                <p
                  style={{ fontSize: "15px" }}
                  dangerouslySetInnerHTML={{
                    __html: courseDetails.tdescription,
                  }}
                ></p>
              )}
            </div>
          </div>
          <div
            className={Cviewstyle.management__image}
            style={{
              position: "relative",
              height: "290px",
              borderRadius: "10px",
            }}
          >
            {(!editObj.meditmode ||
              refselectedCourseFile.current === undefined) &&
              !isLoading && (
                <img
                  src={courseDetails.topicimg}
                  className={Caviewstyle.eimagebox}
                />
              )}
            {isLoading ? <Skeleton variant="rect" height={210} /> : null}
            {editObj.meditmode && (
              <>
                {refselectedCourseFile.current !== undefined && (
                  <img
                    className={Caviewstyle.eimagebox}
                    src={URL.createObjectURL(refselectedCourseFile.current)}
                  />
                )}
                <input
                  type="file"
                  id="cfiles"
                  style={{ visibility: "hidden", position: "absolute" }}
                  onChange={changeHandler}
                  accept="Image/*"
                />
                <label
                  for="cfiles"
                  style={{ position: "absolute", right: "10px", bottom: "6px" }}
                >
                  <AddAPhotoIcon style={{ color: "#ddd", cursor: "pointer" }} />
                </label>
              </>
            )}
          </div>
        </div>
      </div>
      <Tab
        panes={panes}
        onTabChange={(e, { activeIndex }) => handleTabChange(activeIndex)}
        activeIndex={activeIndex}
      />
    </div>
  );
}
let styles = {
  editButton: {
    color: "white",
    borderStyle: "groove ",
    borderColor: Constants.main_color_2,
    height: "30px ",
    lineHeight: "0px ",
    width: "150px",
    marginTop: "15px",
    marginRight: "50px",
    backgroundColor: Constants.main_color_2,
    fontSize: "15px ",
    borderRadius: "3px ",
    float: "right",
    fontWeight: "bold",
  },
  enableDisable: {
    color: "black",
    borderStyle: "groove ",
    borderColor: "black",
    height: "30px ",
    lineHeight: "0px ",
    width: "150px",
    backgroundColor: "white",
    fontSize: "15px ",
    borderRadius: "3px ",
    float: "right",
  },
};
