import React, { useState, useEffect, useRef } from "react";
import { API } from "aws-amplify";
import ClockGray from "../../../assets/svgjs/ClockGray";
import Cviewstyle from "./courseCreation.module.scss";
import ObjectPopUp from "./AddObjects";
import UserHeader from "../../Header/UserHeader/UserHeader";
import { Constants } from "../../../config/constants";
import config from "../../../config/aws-exports";
import { Tab } from "semantic-ui-react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData, awsSignOut } from "../../../redux/auth/authSlice";
import {
  AccordionSummary,
  AccordionDetails,
  Accordion,
  Typography,
  Input,
  Button,
  Backdrop,
  makeStyles,
  FormControl,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  TextareaAutosize,
  CircularProgress,
  ButtonBase,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Skeleton from "@material-ui/lab/Skeleton";
import Video from "../../../assets/svgjs/Video";
import Review from "../../../assets/svgjs/Review";
import Html from "../../../assets/svgjs/Html";
import QuizIc from "../../../assets/svgjs/Quiz";
import PdfIcon from "../../../assets/svgjs/Pdf";
import black from "../../../assets/images/black.png";
import addpluse from "../../../assets/images/add.png";
import addimg from "../../../assets/images/addimg.png";
import Audio from "../../../assets/svg/Audio.svg";
import Scrom from "../../../assets/images/scorm.png";
import { textAlign } from "@material-ui/system";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import CloseIcon from "@material-ui/icons/Close";
import RegexTextField from "./RegexTextField";
import swal from "sweetalert";
import axios from "axios";

import AssignmentAndAssessment from "../../AssignmentAndAssessment/AssignmentAndAssessment";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  "& > *": {
    margin: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function CourseCreation({ handleToggleSidebar }) {
  const classes = useStyles();
  let navigate = useNavigate();
  //const [course, setCourse] = useState({tdescription:"Course Description"});
  const [courseDetails, setCourseDetails] = useState({
    tdescription: "Course Description",
  });
  let topicdata = {
    ttitle: "",
    tdescription: "",
    freenavigation: false,
    certification: false,
    tcertauth: "Enhanzed",
    tnoofcredits: 0,
    tduration: 0,
    version: 1,
    noofnuggets: 0,
    tobj: 0,
    tvid: 0,
    tdoc: 0,
    tfq: 0,
    tsq: 0,
    tasmt: 0,
  };
  const onlyAlphanumericRegex = /[^0-9]/gi;
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [istLoading, setIstLoading] = useState(false);
  const [curObject, setcurObject] = useState({});
  const [nuggets, setNuggets] = useState([]);
  const [objOpen, setObjOpen] = useState(false);
  const [editObj, setEditObj] = useState({ editbtn: true });
  const [topicData, setTopicData] = useState(topicdata);
  const refselectedCourseFile = useRef();
  const redux = useRef({});
  const refUnitValue = useRef("");
  const refnuggetValue = useRef("");
  const [activeIndex, setActiveIndex] = useState(1);
  const [folder, setFolder] = useState(Math.round(new Date().getTime() / 1000));
  const [cNugget, setcNugget] = useState({});
  const [errors, setErrors] = useState({ uval: false, nval: false });
  const referrors = useRef({});

  //const [refUnitValue, setrefUnitValue] = useState({})

  let userDetails = useSelector(authData);
  const dispatch = useDispatch();
  let lcourseDetails = userDetails.curprgcou;

  useEffect(() => {
    reduxRestore();
    setIsLoading(false);
    breadcrumb();
  }, []);
  // useEffect(() => {

  // }, [nuggets])
  const renderSwitch = (param) => {
    if (param === "video") {
      return <Video className="video-size" cls1="cls-1--v" cls2="cls-2--v" />;
    } else if (param === "audio") {
      return <img src={Audio} style={{ height: "32px" }} />;
    } else if (param === "quiz") {
      return <QuizIc className="quizAct" />;
    } else if (param === "html") {
      return (
        <Html
          className="video-size"
          cls1="cls-1--H"
          cls2="cls-2--H"
          cls3="cls-3--H"
        />
      );
    } else if (param === "Interactivity") {
      return <Review className="video-size" cls1="cls-1--R" cls2="cls-2--R" />;
    } else if (param === "pdf") {
      return <PdfIcon className="pdfAct" />;
    }
    else if (param === "scorm") {
      return  <img src={Scrom} style={{ height: "32px" }} />;
    }
  };
  function reduxRestore() {
    let sdata = { ...userDetails };
    redux.current = sdata;
  }
  function addnugget() {}

  function CourseStructure() {
    return (
      <div>
        <Typography component="div" variant="h1">
          {isLoading ? <Skeleton /> : null}
        </Typography>
        <Typography component="div" variant="h1">
          {isLoading ? <Skeleton /> : null}
        </Typography>
        <Typography component="div" variant="h1">
          {isLoading ? <Skeleton /> : null}
        </Typography>
        <Typography component="div" variant="h1">
          {isLoading ? <Skeleton /> : null}
        </Typography>
        {/* <pre>{JSON.stringify(topics, null, 2)}</pre> */}
        <div>
          <ObjectPopUp
            objOpen={objOpen}
            setObjOpen={setObjOpen}
            renderSwitch={renderSwitch}
            folder={folder}
            cNugget={cNugget}
            setNuggets={setNuggets}
            nuggets={nuggets}
          />
          {nuggets.map(
            ({ nid, unit, ntitle, nduration, objects, nenabled }, idx) => (
              <div className={Cviewstyle.nuggets}>
                <div className={Cviewstyle.displayflex + " padding2"}>
                  {/* <h3 className="item" style={{ fontWeight: "bold" }}>
                    
                      {ntitle}
                    </h3> */}
                  <Input
                    defaultValue={ntitle}
                    style={{ fontWeight: "bold" }}
                    className={Cviewstyle.inputsize}
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    inputProps={{ "aria-label": "description" }}
                  />
                  <button
                    onClick={() => {
                      nuggets.splice(idx, 1);
                      setNuggets([...nuggets]);
                    }}
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      padding: "0px 20px",
                      background: "#e35f14",
                      border: "none",
                      borderRadius: "5px",
                      color: "#fff",
                      width: "195px",
                      height: "30px",
                    }}
                  >
                    Remove Module
                  </button>
                </div>
                <div className={Cviewstyle.objects}>
                  {objects.map(
                    (
                      {
                        oid,
                        otitle,
                        odescription,
                        otype,
                        oduration,
                        ourl,
                        userOP,
                      },
                      oidx
                    ) => (
                      <div
                        className="flex size"
                        style={{
                          justifyContent: "space-between",
                          width: "calc(100% - 4.5%)",
                        }}
                      >
                        <p style={{ cursor: "pointer" }}>
                          <span>{renderSwitch(otype)}</span>
                          <a
                            style={{ paddingLeft: "10px" }}
                            href
                            onClick={() => {}}
                          >
                            {otitle}
                          </a>
                        </p>

                        <span>
                          <button
                            onClick={() => {
                              nuggets[idx].objects.splice(oidx, 1);
                              setNuggets([...nuggets]);
                            }}
                            style={{
                              border: "none",
                              background: "transparent",
                            }}
                          >
                            <CloseIcon
                              fontSize="10px"
                              style={{ color: "red" }}
                            />
                          </button>
                        </span>
                      </div>
                    )
                  )}
                  <div className={Cviewstyle.addnugget}>
                    <div>
                      <p
                        style={{
                          fontSizesize: "14px",
                          margin: "5px 0",
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setcNugget({ nid, ntitle, objects, idx });
                          setObjOpen(true);
                        }}
                      >
                        <img
                          style={{
                            height: "25px",
                            border: "none",
                            cursor: "pointer",
                            margin: "0 15px",
                          }}
                          src={addpluse}
                        />
                        Add objects
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>

        <div className={Cviewstyle.addnugget}>
          <div>
            <p
              style={{
                fontSizesize: "14px",
                margin: "5px 0",
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={handleClickOpen}
            >
              <img
                style={{
                  height: "25px",
                  border: "none",
                  cursor: "pointer",
                  margin: "0 15px",
                }}
                src={addpluse}
              />
              Add Nugget
            </p>
          </div>
          <Dialog
            open={open}
            fullWidth="true"
            maxWidth="sm"
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              <h2 className={Cviewstyle.popadd}>Add Nugget</h2>
            </DialogTitle>
            <form>
              <DialogContent>
                <div className={Cviewstyle.inputholder}>
                  <input
                    defaultValue={refUnitValue.current}
                    id="standard-error-helper-text"
                    placeholder="Unit Name"
                    type="text"
                    className={Cviewstyle.inputbox}
                    onChange={(event) => {
                      const { value } = event.target;
                      refUnitValue.current = value;
                    }}
                  />
                  <input
                    defaultValue={refnuggetValue.current}
                    id="name"
                    placeholder="Nugget Name"
                    type="text"
                    className={Cviewstyle.inputbox}
                    onChange={(event) => {
                      const { value } = event.target;
                      refnuggetValue.current = value;
                      //setrefUnitValue({nugget: value});
                    }}
                  />
                </div>
              </DialogContent>
              <div className={Cviewstyle.buttonholder}>
                <DialogActions>
                  <button onClick={handleClose} className={Cviewstyle.cancel}>
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      // errors.uval = false;
                      // errors.nval = false;
                      // alert(
                      //   "Unit Name " + JSON.stringify(refUnitValue.current)
                      // );
                      // alert(
                      //   "Nugget Name " + JSON.stringify(refnuggetValue.current)
                      // );
                      if (
                        refUnitValue.current == undefined ||
                        refUnitValue.current.toString().replace(/\s/g, "")
                          .length == 0
                      ) {
                        swal("Please Enter Unit Name");
                        return;
                      } else if (
                        refnuggetValue.current == undefined ||
                        refnuggetValue.current.toString().replace(/\s/g, "")
                          .length == 0
                      ) {
                        swal("Please Enter Nugget Name");
                        return;
                      } else {
                        let objs = {};
                        objs.nid = nuggets.length + 1;
                        objs.nenabled = false;
                        objs.ntitle = refnuggetValue.current;
                        objs.objects = [];
                        objs.unit = refUnitValue.current;
                        nuggets.push(objs);
                        setNuggets(nuggets);
                        refUnitValue.current = "";
                        refnuggetValue.current = "";

                        handleClose();
                      }
                    }}
                    className={Cviewstyle.save}
                  >
                    Save  
                  </button>
                </DialogActions>
              </div>
            </form>
          </Dialog>
        </div>
      </div>
    );
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    refUnitValue.current = "";
    refnuggetValue.current = "";
    setOpen(false);
  };
  const panes = [
    {
      menuItem: "Overview",
      render: () => (
        <Tab.Pane>
          <div>
            <TextareaAutosize
              maxRows={15}
              minRows={10}
              aria-label="maximum height"
              placeholder=""
              defaultValue={topicData.tdescription}
              style={{ width: "100%", fontSize: "20px" }}
              onChange={(e) => {
                topicData.tdescription = e.target.value;
                setTopicData((prevtopicData) => ({
                  ...prevtopicData,
                  ...topicData,
                }));
              }}
            />
            <h3>Course Duration : </h3>
            <RegexTextField
              regex={onlyAlphanumericRegex}
              label="Weeks"
              defaultValue={topicData.tduration}
              onChange={(e) => {
                topicData.tduration = e.target.value;
                setTopicData((prevtopicData) => ({
                  ...prevtopicData,
                  ...topicData,
                }));
              }}
            />
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Content",
      render: () => (
        <Tab.Pane>
          <CourseStructure />
        </Tab.Pane>
      ),
    },
    // {
    //   menuItem: "Assignment",
    //   render: () => (
    //     <Tab.Pane>
    //       <AssignmentAndAssessment type="Assignment" />
    //     </Tab.Pane>
    //   ),
    // },
    // {
    //   menuItem: "Assessment",
    //   render: () => (
    //     <Tab.Pane>
    //       <AssignmentAndAssessment type="Assessment" />
    //     </Tab.Pane>
    //   ),
    // },
  ];
  const changeHandler = (event) => {
    if (event.target.files == null) {
      return;
    }
    if (event.target.files[0].size > 5242880) {
      swal(
        "Oops!",
        "Image size is too large. File size cannot be greater than 5mb.",
        "error"
      );
      return;
    }
    if (event.target.files[0] !== undefined) {
      refselectedCourseFile.current = event.target.files[0];
      //setSelectedCourseFile((currentState) => (event.target.files[0]));
      var body = {
        filetype: event.target.files[0].type,
        filename: "topic.png",
        folder: folder,
      };

      uploadfile(body);
    }
  };
  function checkData() {
    if (topicData.ttitle == "" || topicData.ttitle.length === 0) {
      swal("Oops!", "Course title cannot be empty", "error");
      return;
    }
    if (topicData.tdescription == "" || topicData.tdescription.length === 0) {
      swal(
        "Oops!",
        "Course description cannot be empty in Overview section",
        "error"
      );
      return;
    }
    if (refselectedCourseFile.current === undefined) {
      swal("Oops!", "Please select a Course image.", "error");
      return;
    }
    if (nuggets.length === 0) {
      swal("Oops!", "You need to add atleast 1 Module.", "error");
      return;
    }
    if (nuggets.length > 0) {
      for (var i = 0; i < nuggets.length; i++) {
        if (nuggets[i].objects.length == "0") {
          swal(
            "Oops!",
            "You need to add atleast 1 Content in each Module",
            "error"
          );
          return;
        }
      }

      saveTopicGetID();
    }
  }
  async function uploadfile(body) {
    setIstLoading(true);
    const bodyParam = {
      body,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const preSignedData = await API.post(
        config.aws_cloud_logic_custom_name,
        //Constants.GET_PRESIGNED_URL,
        "/getpreSignedURL",
        bodyParam
      );

      axios
        .put(preSignedData, refselectedCourseFile.current, {
          headers: {
            "Content-Type": refselectedCourseFile.current.type,
            "Cache-Control": "no-cache no-store must-revalidate",
          },
        })
        .then((response) => {
          console.log("file upload success");
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
      setIstLoading(false);
    } catch (error) {
      // alert(JSON.stringify(error));
      console.error(error);
    }
  }
  async function saveTopicGetID() {
    setIstLoading(true);
    let ndur = 0,
      tobj = 0,
      tvid = 0,
      tdoc = 0,
      tsq = 0,
      tfq = 0,
      tdur = 0;
    for (let i = 0; i < nuggets.length; i++) {
      ndur = 0;
      for (let o = 0; o < nuggets[i].objects.length; o++) {
        tobj++;
        ndur = ndur + Number(nuggets[i].objects[o].oduration);
        tdur = tdur + Number(nuggets[i].objects[o].oduration);
        if (
          nuggets[i].objects[o].otype == "video" ||
          nuggets[i].objects[o].otype == "audio"
        ) {
          tvid++;
        } else if (nuggets[i].objects[o].otype == "quiz") {
          tsq++;
        } else {
          tdoc++;
        }
        if (o == nuggets[i].objects.length - 1) {
          nuggets[i].nduration = ndur;
        }
      }
    }
    topicData.toduration = tdur;
    topicData.tobj = tobj;
    topicData.tvid = tvid;
    topicData.tdoc = tdoc;
    topicData.tsq = tsq;

    setTopicData((prevtopicData) => ({ ...prevtopicData, ...topicData }));
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        NAME: userDetails.name,
        TOPICNAME: topicData.ttitle,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const getTopicId = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getTopicID",
        bodyParam
      );
      saveTopicDetails(getTopicId);
    } catch (error) {
      setIstLoading(false);
      console.error(error);
    }
  }

  async function saveTopicDetails(topicid) {
    try {
      const bodyParam = {
        body: {
          oid: config.aws_org_id,
          NAME: userDetails.name,
          folder,
          tid: topicid,
          ttitle: topicData.ttitle,
          tdescription: topicData.tdescription,
          freenavigation: topicData.freenavigation,
          certification: topicData.certification,
          tcertauth: topicData.tcertauth,
          tnoofcredits: topicData.tnoofcredits,
          tduration: topicData.tduration,
          toduration: topicData.toduration,
          noofnuggets: nuggets.length,
          nuggets,
          version: 1,
          tobj: topicData.tobj,
          tvid: topicData.tvid,
          tdoc: topicData.tdoc,
          tfq: topicData.tfq,
          tsq: topicData.tsq,
          tasmt: topicData.tasmt,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      if (userDetails.curprgcou !== undefined) {
        if (userDetails.curprgcou.bpid !== undefined) {
          bodyParam.body.pid = userDetails.curprgcou.pid;
          bodyParam.body.bpid = userDetails.curprgcou.bpid;
        }
        // else {
        //   bodyParam.body.pid = userDetails.curprgcou.pid;
        //   bodyParam.body.bpid = userDetails.curprgcou.pid;
        // }
      }
      console.log("topicData===" + JSON.stringify(bodyParam.body, null, 2));
      const getTopicDetails = await API.post(
        config.aws_cloud_logic_custom_name,
        "/createTopic",
        bodyParam
      );
      console.log("create success");
      setIstLoading(false);
      navigate("/Content");
    } catch (error) {
      setIstLoading(false);
      console.error("createtopic" + error);
    }
  }

  function breadcrumb() {
    let sdata = { ...userDetails };
    let temp = [...sdata.breadcrumb];

    temp[3] = {
      name: "Course Creation",
      path: "/courseCreation",
    };

    sdata.breadcrumb = temp;

    dispatch(awsSignIn(sdata));
  }

  return (
    <div className={Cviewstyle.maincontainer}>
      {istLoading && (
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div
        className="btn-toggle"
        onClick={() => handleToggleSidebar(true)}
      ></div>
      <UserHeader Bindex={3} />

      <div className={Cviewstyle.management_info}>
        <div className={Cviewstyle.h1}>
          <h1 className={Cviewstyle.header}>{courseDetails.ttitle}</h1>

          <div style={{display:"flex", gap:"30px"}}>
          <button
         className={Cviewstyle.cancel}

         onClick={() => navigate("/Content")} 
          >
            Discard
          </button>
          
          <button
            variant="contained"
            color="primary"
            className={Cviewstyle.savebutton}
            onClick={() => checkData()}
          >
            Save 
          </button>

          </div>
        </div>

        <div className={Cviewstyle.flex_box} style={{ position: "relative" }}>
          {refselectedCourseFile.current === undefined && (
            <img
              className={Cviewstyle.imagebox}
              style={{ objectFit: "scale-down" }}
              src={addimg}
            />
          )}
          {refselectedCourseFile.current !== undefined && (
            <img
              className={Cviewstyle.imagebox}
              src={URL.createObjectURL(refselectedCourseFile.current)}
            />
          )}
          <img src={black} className={Cviewstyle.imgbl} />
          <Input
            className={Cviewstyle.inputsize + " " + Cviewstyle.ctitle}
            onChange={(e) => {
              topicData.ttitle = e.target.value;
              setTopicData((prevtopicData) => ({
                ...prevtopicData,
                ...topicData,
              }));
            }}
            inputProps={{
              "aria-label": "description",
              placeholder: "Title",
              style: { textAlign: "center" },
            }}
          />
          <input
            type="file"
            id="cfiles"
            style={{ visibility: "hidden", position: "absolute" }}
            onChange={changeHandler}
            accept="Image/*"
          />
          <label
            for="cfiles"
            style={{ position: "absolute", right: "10px", bottom: "6px" }}
          >
            <AddAPhotoIcon style={{ color: "#ddd", cursor: "pointer" }} />
          </label>
        </div>
      </div>
      <Tab
        panes={panes}
        activeIndex={activeIndex}
        onTabChange={(e, { activeIndex }) => {
          setActiveIndex(activeIndex);
        }}
      />
    </div>
  );
}
let styles = {
  editButton: {
    color: "white",
    borderStyle: "groove ",
    borderColor: Constants.main_color_2,
    height: "30px ",
    lineHeight: "0px ",
    width: "150px",
    marginTop: "15px",
    marginRight: "50px",
    backgroundColor: Constants.main_color_2,
    fontSize: "15px ",
    borderRadius: "3px ",
    float: "right",
    fontWeight: "bold",
  },
  enableDisable: {
    color: "black",
    borderStyle: "groove ",
    borderColor: "black",
    height: "30px ",
    lineHeight: "0px ",
    width: "150px",
    backgroundColor: "white",
    fontSize: "15px ",
    borderRadius: "3px ",
    float: "right",
  },
};
// "Introductory Video": [
//     {
//       "nid": "CR001-1",
//       "unit": "Introductory Video",
//       "ntitle": "Introductory Video",
//       "nduration": 5,
//       "objects": [
//         {
//           "oid": "CR001-1-1",
//           "otitle": "Introductory Video",
//           "odescription": "Introductory Video",
//           "otype": "video",
//           "oduration": "5",
//           "oytid": "HRgKGWTH1Xo",
//           "ourl": "CR001-1-1.mp4"
//         }
//       ],
//       "nenabled": true
//     }
//   ]
