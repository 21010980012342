import React, { useState, useEffect, useRef } from "react";
import { Button, Input, Fade, Backdrop,  AccordionSummary,
    AccordionDetails, Accordion, makeStyles, TextareaAutosize,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField, } from "@material-ui/core";
    import swal from "sweetalert";

    const useStyles = makeStyles((theme) => ({
      root: {
        width: '100%',
        '& > * + *': {
          marginTop: theme.spacing(2),
        },
      },
    }));
    export default function DialogBox({nopen,setNuggets, nuggets,setNopen,setIsLoading,courseDetails,
      errors, setErrors }) {
    const refnuggetValue = useRef();
    const refUnitValue = useRef("");
    const [fromData, setFromData] = useState({uval:"", nval:""});
    const [isdload,setIsdload] = useState(false);
    const classes = useStyles();
        function handleNuggetsClose() {
            setNopen(false);
            setIsLoading(false);
          }
          return (
          
        <Dialog
        open={nopen}
        fullWidth="true"
        maxWidth="sm"
        onClose={handleNuggetsClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Nugget</DialogTitle>
        <DialogContent>
          {!isdload &&  <form>
       
          <TextField
                  margin="dense"
                  id="uname"
                  label="Unit Name"
                  type="text"
                  fullWidth
                  inputProps={{ style: { fontSize: 18 } }} // font size of input text
                  InputLabelProps={{ style: { fontSize: 18 } }}
                  onChange={(event) => {
                    const { value } = event.target;
                    refUnitValue.current = value;
                   
                  }}
                />
            <TextField
              margin="dense"
              id="name"
              label="Nugget Name"
              type="text"
              fullWidth
              inputProps={{ style: { fontSize: 18 } }} // font size of input text
              InputLabelProps={{ style: { fontSize: 18 } }}
              onChange={(event) => {
                const { value } = event.target;
                refnuggetValue.current = value;
               
                // setrefUnitValue({nugget: value});
              }}
            />
          </form> }
         
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNuggetsClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              setIsdload(true);
             let tmpnt = [...nuggets];
              let objs = {};
              let cnid = 1;
              errors.uval = false;
              errors.nval = false;
              if(nuggets !== undefined && nuggets.length > 0){
                cnid = (Number(nuggets[nuggets.length - 1].nid.substr(nuggets[nuggets.length - 1].nid.length - 1)) + 1);
              }
              if(refUnitValue.current === undefined || refUnitValue.current.toString().replace(/\s/g, "").length === 0){
                swal(
                  "Oops!",
                  "Please Enter Unit Name",
                  "error"
                );
                 setIsdload(false);
               } else  if(refnuggetValue.current === undefined || refnuggetValue.current.toString().replace(/\s/g, "").length === 0){
                swal(
                  "Oops!",
                  "Please Enter Nugget Name",
                  "error"
                );
                setIsdload(false);
              } else {
                setIsLoading(true);
                setIsdload(false);
                objs.nid = courseDetails.tid+"-"+ cnid ;
                objs.nenabled = false;
                objs.ntitle = refnuggetValue.current;
                objs.objects = [];
                objs.unit = refUnitValue.current;
                tmpnt.push(objs);
                setNuggets(tmpnt);
               console.log(JSON.stringify(tmpnt,null,2))
                // setNuggets(prevState => {
                //   return Object.assign({}, prevState, nuggets);
                // });
                handleNuggetsClose();
              }
              
            }}
            color="primary"
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              padding: "0px 20px",
              background: "#e35f14",
              border: "none",
              borderRadius: "5px",
              width: "fit-content",
              height: "30px",
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
          )
    }